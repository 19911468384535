import React from 'react'
import tw, { styled } from 'twin.macro'
import Navbar from '../../components/elements/Navbar'
import { Subtitle } from '../datenschutz'
import components from '../../content/common'
import Footer from '../../components/elements/Footer'
import pressePreviewImage from '@images/presse/festl-brass-preview.jpg'
import presseImage1 from '@images/presse/festl-brass-festzelt-preview.jpg'
import presseImage2 from '@images/presse/festl-brass-fenzl-preview.jpg'
import presseImage3 from '@images/presse/festl-brass-deschowieda-preview.jpg'

const Section = styled.section`
  ${tw`w-full bg-radler pb-20 pt-20 lg:pb-40 lg:pt-56 overflow-hidden`}

  p {
    ${tw`text-white pt-5 text-15px lg:text-18px font-roboto`}
    b {
      ${tw``}
    }
  }

  a.text-green {
    ${tw`text-gelb`}
    &:hover {
      filter: drop-shadow(1px 2px 0.75rem rgba(247, 211, 3, 1));
    }
  }
`

const ContentWrap = tw.div`max-w-master mx-auto`
const ContentRow = tw.div`grid grid-cols-12 gap-4 mt-4`
const ContentImageWrapper = tw.div`flex justify-center mt-8`
const ContentText = tw.div`col-span-12 xs:col-span-8`
const ImageSource = tw.small`text-white`
const ContentRowImage = tw.div`col-span-12 xs:col-span-6`

const PressePage = () => {
  const presseImage = '/download/festl-brass.jpg';
  const presseImageDl1 = '/download/fest-brass-festzelt.jpg';
  const presseImageDl2 = '/download/festl-brass-fenzl.jpg';
  const presseImageDl3 = '/download/festl-brass-deschowieda.jpg';

  return (
    <>
      <Navbar content={components.navbar} />
        <Section>
          <ContentWrap>
            <div tw="max-w-screen-2xl mx-auto px-3 sm:px-7">
              <Subtitle tw="max-w-full">
                Das gab es noch nie! Brass Sonntag auf dem Herbstfest in Erding
              </Subtitle>
              <ContentText>
                <p>
                  <strong>
                    Furioses Finale der Alpin FM Festl Tour mit ERDINGER Brauhaus
                  </strong>
                </p>
                <p>
                  Erding, August 2023<br />
                  Der Auftakt der Alpin FM Festl Tour in Bad Reichenhall: phänomenal.
                  Die Events in Oberaudorf und Ruhpolding: überragend. Doch das Finale in Erding wird einfach
                  nur legendär! Zum ersten Mal findet auf dem Herbstfest am ersten Sonntag ein FestivalKonzert 
                  mit vier spektakulären Mundart-Bands statt – darunter die local heroes von
                  DeSchoWieda und die österreichische Feel-Good-Garantie Granada.
                </p>
                <p>
                  Granada, das ist unnachahmlicher Schmäh, ungezwungene Leichtigkeit und 
                  unverwechselbarer Sound – einfach Neo-Austropop vom Feinsten. Zudem werden
                  DeSchoWieda nach ihrem fulminanten Jubiläums-Auftritt im Mai ihre Heimatstadt Erding
                  erneut zum Tanzen bringen. Die oberbayerischen Mundart-Bands Luegstoa C und Fenzl
                  runden das sensationelle Line-up ab. Daher gibt es am ersten Herbstfest-Sonntag (27.
                  August) ab 13 Uhr nur einen place to be: das ERDINGER Weißbräu-Festzelt. Jeder ist herzlich
                  eingeladen mitzufeiern – der Eintritt ist frei.
                </p>
                <p>
                  Tanzen und Feiern machen natürlich Hunger und Durst. Keine Herausforderung für das Team
                  vom ERDINGER Weißbräu-Festzelt. ERDINGER Bierspezialitäten – während des Konzerts auch
                  die ERDINGER Brauhaus-Biere – sowie alkoholfreie Getränke sorgen für einzigartige
                  Erfrischung. Zur Stärkung gibt es allerlei bayerische Schmankerl – von der Brotzeit über
                  Schweinebraten bis zum Kaiserschmarrn. Alpin FM, die Bands und ERDINGER freuen sich auf
                  das spektakuläre Finale der diesjährigen Festl Tour.
                </p>
                <p>
                  Homepage: <a href="https://www.erdinger-brauhaus.de/events/" target="_blank">www.erdinger-brauhaus.de/events/</a><br />
                  Instagram: <a href="https://www.instagram.com/erdinger.brauhaus/" target="_blank">@erdinger.brauhaus</a>
                </p>
              </ContentText>
              <ContentImageWrapper>
                <div>
                  <a 
                      href={presseImage}
                      target="_blank"
                    >
                      <img src={pressePreviewImage} alt="Das große Finale der Alpin FM Festl Tour im ERDINGER Weißbräu-Festzelt" />
                  </a>
                  <ImageSource>Bildquelle: ERDINGER Weißbräu</ImageSource>
                </div>
              </ContentImageWrapper>

              <br />
              <ContentImageWrapper>
                <div>
                  <a 
                      href={presseImageDl1}
                      target="_blank"
                    >
                      <img src={presseImage1} alt="Das große Finale der Alpin FM Festl Tour im ERDINGER Weißbräu-Festzelt" />
                  </a>
                  <ImageSource>Bildquelle: Alex Gschloessl</ImageSource>
                </div>
              </ContentImageWrapper>
              <p tw="text-center">Das große Finale der Alpin FM Festl Tour im ERDINGER Weißbräu-Festzelt</p>

              <br />
              <br />
              <ContentRow>
                <ContentRowImage>
                  <a 
                      href={presseImageDl2}
                      target="_blank"
                    >
                    <img src={presseImage2} alt="Fenzl" />
                  </a>
                  <ImageSource>Bildquelle: Alex Schmidt</ImageSource>
                </ContentRowImage>
                <ContentRowImage>
                  <a 
                      href={presseImageDl3}
                      target="_blank"
                    >
                    <img src={presseImage3} alt="DeSchoWieda" />
                  </a>
                  <ImageSource>	Bildquelle: Darius Kappes</ImageSource>
                </ContentRowImage>
              </ContentRow>
              <p tw="text-center">Neben Granada und Luegstoa C heizen auch Fenzl (Bild links) und DeSchoWieda (Bild rechts) den Gästen am Brass Sonntag ordentlich ein</p>
            </div>
          </ContentWrap>
        </Section>
      <Footer stickyVisible={false} />
    </>
  )
}

export default PressePage
